var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.isLoading),expression:"isLoading",modifiers:{"fullscreen":true,"lock":true}}],attrs:{"id":"manager-users"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.filterListUsers},on:{"row-click":_vm.rowClicked}},[_c('el-table-column',{attrs:{"label":'avatar',"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{staticClass:"slot-avatar",attrs:{"src":row.avatar || _vm.imageDefault}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.lang.username,"width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.username))]}}])}),_c('el-table-column',{attrs:{"label":_vm.lang.fullName,"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.fullName))]}}])}),_c('el-table-column',{attrs:{"label":_vm.lang.position,"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.permission(row.permission)))]}}])}),_c('el-table-column',{attrs:{"width":"160","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_vm._v(_vm._s(_vm.lang.edit))]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(scope.$index, scope.row)}}},[_vm._v(_vm._s(_vm.lang.delete))])]}}])})],1),_c('el-pagination',{staticStyle:{"text-align":"center","margin-top":"1rem"},attrs:{"layout":"prev, pager, next","total":_vm.users.length,"page-size":_vm.pageSize},on:{"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }